// Import our CSS
import styles from '../scss/manual.scss';


// App main
const main = async () => {
	// Async load the vue module
	const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

	// Create our vue instance
	const vm = new Vue({
		el: "#app",
		delimiters: ['${', '}'],
		components: {},
		props:{
			loadedMenu: {
				type: String,
				default: '',
			}
		},
		data() {
			return {
				active: this.loadedMenu,
				isMenuOpen: false
			}
		},
		methods: {
			openMenu (){
				this.isMenuOpen = true
			},
			closeMenu (){
				this.isMenuOpen = false
			}
		},
		computed: {

		},
		created(){},
		destroyed () {},
		mounted() {
		},
		beforeCreate() {
			const el = document.querySelector(this.$options.el)
			this.$options.propsData = {
				...el.dataset
			}

		}
	});

	return vm;
};

// Execute async function
main().then( (vm) => {

});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}
